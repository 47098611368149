import { Box, enerbitColors, Typography } from "@enerbit/base";
import Backbutton from "../../../components/Buttons/back-button";
import { generateRandomId } from "../../../helpers";
import ResultItem from "./result-item";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useEstimationsState } from "../../../store";

const ZeroEstimationResults = () => {
    const { estimationResults } = useEstimationsState((state) => state);
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/");
    };

    useEffect(() => {
        if (estimationResults.length === 0) {
            handleBack();
        }
    }, []);

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Backbutton onClick={handleBack} />
                <Box>
                    <Typography
                        sx={{
                            color: enerbitColors.primary.main,
                            fontSize: "28px",
                            fontWeight: 700,
                        }}
                    >
                        Lista de estimaciones
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ mt: 3 }}>
                {estimationResults.map((item) => (
                    <Box key={generateRandomId()} sx={{ mb: 2 }}>
                        <ResultItem item={item} />
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default ZeroEstimationResults;
