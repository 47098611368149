export const datePickerStyles = {
    "& fieldset": {
        borderRadius: "12px",
    },
    "& .MuiInputBase-root": {
        height: "41px",
    },
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderRadius: "12px",
    },
};
