import { useForm, UseFormReturn } from "react-hook-form";
import { ILoadEstimationList } from "../models";
import { createContext, useContext, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { loadEstimationListSchema } from "../validation";

interface CreateEstimationListContext {
    form: UseFormReturn<ILoadEstimationList>;
    emptyFile: boolean;
    setEmptyFile: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateEstimationListContext = createContext<
    CreateEstimationListContext | undefined
>(undefined);

export const useCreateEstimationListContext = () => {
    const context = useContext(CreateEstimationListContext);
    if (!context) {
        throw new Error("useFormContext must be used within a FormProvider");
    }
    return context;
};

export const CreateEstimationListProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const form = useForm<ILoadEstimationList>({
        resolver: zodResolver(loadEstimationListSchema),
        defaultValues: {
            reason_type_id: "",
            observations: "",
            file: undefined,
        },
    });
    const [emptyFile, setEmptyFile] = useState<boolean>(false);

    return (
        <CreateEstimationListContext.Provider
            value={{ form, emptyFile, setEmptyFile }}
        >
            {children}
        </CreateEstimationListContext.Provider>
    );
};
