import { HashRouter, Route, Routes } from "react-router-dom";
import Detail from "../pages/detail/detail";
import EstimatesList from "../pages/estimates-list/estimates-list";
import PreEstimation from "../pages/results/components/pre-estimation";
import Results from "../pages/results/results";

const Router = () => {
    return (
        <HashRouter basename="/estimations">
            <Routes>
                <Route path="/" element={<EstimatesList />} />
                <Route path="/:id" element={<Detail />} />
                <Route path="/pre-estimation" element={<PreEstimation />} />
                <Route path="/results" element={<Results />} />
            </Routes>
        </HashRouter>
    );
};

export default Router;
