import { Box } from "@enerbit/base";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EstimationQueryParamValues } from "../../models";
import ZeroEstimationResults from "./components/zero-estimations-results";
import { estimationTypeQueryKey } from "../../common";
import PreEstimation from "./components/pre-estimation";

const Results = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const type = searchParams.get(estimationTypeQueryKey);

    useEffect(() => {
        if (!type) {
            navigate("/");
        }
    }, []);

    return (
        <Box>
            {type === EstimationQueryParamValues.ZERO && (
                <ZeroEstimationResults />
            )}
            {type === EstimationQueryParamValues.LIST && <PreEstimation />}
        </Box>
    );
};

export default Results;
