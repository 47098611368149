import { Box, enerbitColors, Typography } from "@enerbit/base";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Backbutton from "../../components/Buttons/back-button";
import { useEstimationsState } from "../../store";
import Information from "./components/information";
import EstimatedData from "./components/estimated-data";

const Detail = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { getEstimationById } = useEstimationsState((state) => state);

    useEffect(() => {
        if (!id) return;

        getEstimationById(id);
    }, []);

    const handleBack = () => {
        navigate("/");
    };

    return (
        <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Backbutton onClick={handleBack} />
                <Box>
                    <Typography sx={{ color: enerbitColors.neutral[700] }}>
                        Estimación
                    </Typography>
                    <Typography
                        sx={{
                            color: enerbitColors.primary.main,
                            fontSize: "28px",
                            fontWeight: 700,
                        }}
                    >
                        {id}
                    </Typography>
                </Box>
            </Box>

            <Box mt={3}>
                <Information />
            </Box>
            <Box mt={3}>
                <EstimatedData />
            </Box>
        </Box>
    );
};

export default Detail;
