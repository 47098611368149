import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@enerbit/base";
import { StyledTableHeader } from "./StyledTable";
import { generateRandomId } from "../../helpers";

interface Props {
    columns: number;
    rows: number;
}

const TableSkeleton = ({ columns, rows }: Props) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <StyledTableHeader>
                        {[...Array(columns)].map(() => (
                            <TableCell key={generateRandomId()}>
                                <Skeleton variant="text" />
                            </TableCell>
                        ))}
                    </StyledTableHeader>
                </TableHead>
                <TableBody>
                    {[...Array(rows)].map(() => (
                        <TableRow key={generateRandomId()}>
                            {[...Array(columns)].map(() => (
                                <TableCell key={generateRandomId()}>
                                    <Skeleton variant="text" />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableSkeleton;
