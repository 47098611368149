import { enerbitColors, styled } from "@enerbit/base";
import { Box } from "@mui/system";

const SectionContainer = styled(Box)(() => ({
    border: `1px solid ${enerbitColors.primary[200]}`,
    padding: "12px",
    borderRadius: "8px",
}));

export default SectionContainer;
