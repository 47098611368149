import { enerbitColors } from "@enerbit/base";

export const ESTIMATION_STATE: Record<
    string,
    { fontcolor: string; bgcolor: string; label: string }
> = {
    new: {
        bgcolor: enerbitColors.success[100] || "",
        fontcolor: enerbitColors.success.main || "",
        label: "Nueva",
    },
    old: {
        bgcolor: "#EEEBF4",
        fontcolor: "#53358E",
        label: "Antigua",
    },
    incoming: {
        bgcolor: enerbitColors.warning[100] || "",
        fontcolor: enerbitColors.warning.main || "",
        label: "En ingreso",
    },
};

export const OBSERVATIONS: Record<string, string> = {
    WH: "Sin huecos.",
    DNF: "Fecha no encontrada.",
    MTH: "Se encontró mas de un hueco.",
    HF: "Hueco encontrado.",
    MNF: "Medidor no encontrado.",
    DDGH: "La diferencia de las fechas es mayor a 48 horas.",
    VLH: "Hueco demasiado grande.",
};
