import {
    Box,
    Button,
    enerbitColors,
    Grid,
    moment,
    styled,
    SxProps,
    Typography,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import ChargingStationIcon from "@mui/icons-material/ChargingStation";
import { IEstimationResults } from "../../../models";
import StatusTag from "../../../components/container/status-tag";
import { useEstimationsState } from "../../../store";
import { DATE_TIME_INPUT_FORMAT, OBSERVATIONS } from "../../../common";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorModal from "./error-modal";

interface Props {
    item: IEstimationResults;
}

const subTitleProps: SxProps = {
    color: enerbitColors.neutral.main,
    fontSize: "12px",
};

const titleProps: SxProps = {
    fontWeight: 700,
    color: enerbitColors.primary.main,
};

const ResultItem = ({ item }: Props) => {
    const { meterModels } = useEstimationsState((state) => state);
    const [showFailReason, setShowFailReason] = useState<boolean>(false);

    const navigate = useNavigate();

    const getEstimationRange = () => {
        if (item.fail_reason) return "No aplica";

        return (
            moment(item.start_at).format(DATE_TIME_INPUT_FORMAT) +
            " - " +
            moment(item.end_at).format(DATE_TIME_INPUT_FORMAT)
        );
    };

    return (
        <>
            <SectionContainer>
                <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                    <IconContainer bgcolor={"#8B76D6"}>
                        <ChargingStationIcon sx={{ color: "#FFF" }} />
                    </IconContainer>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={2.4}>
                            <Typography sx={subTitleProps}>
                                Serial medidor
                            </Typography>
                            <Typography sx={titleProps}>
                                {item.meter_serial}
                            </Typography>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Typography sx={subTitleProps}>
                                Modelo medidor
                            </Typography>
                            <Typography sx={titleProps}>
                                {meterModels.find(
                                    (model) => model.code === item.model_code,
                                )?.name ?? "Desconocido"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Typography sx={subTitleProps}>
                                Rango estimado
                            </Typography>
                            <Typography sx={titleProps}>
                                {getEstimationRange()}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={2.4}
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <StatusTag
                                    sx={{ maxWidth: "72px" }}
                                    bgcolor={
                                        item.fail_reason ? "#FEECEB" : "#E7F8F0"
                                    }
                                    fontcolor={
                                        item.fail_reason ? "#F04438" : "#12B76A"
                                    }
                                >
                                    {item.fail_reason ? "Fallido" : "Creado"}
                                </StatusTag>
                            </Box>
                        </Grid>
                        {item.fail_reason && (
                            <Grid
                                item
                                xs={2.4}
                                display="flex"
                                justifyContent="center"
                                flexDirection="column"
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        sx={{ height: "33px" }}
                                        onClick={() => {
                                            setShowFailReason(true);
                                        }}
                                    >
                                        Ver motivo
                                    </Button>
                                </Box>
                            </Grid>
                        )}
                        {!item.fail_reason && (
                            <Grid
                                item
                                xs={2.4}
                                display="flex"
                                justifyContent="center"
                                flexDirection="column"
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ height: "33px" }}
                                        onClick={() => {
                                            navigate(`/${item.id}`);
                                        }}
                                    >
                                        Ver detalle
                                    </Button>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </SectionContainer>
            {item.fail_reason && (
                <ErrorModal
                    title="Creación fallida"
                    message={OBSERVATIONS[item.fail_reason]}
                    isOpen={showFailReason}
                    handleClose={() => {
                        setShowFailReason(false);
                    }}
                />
            )}
        </>
    );
};

export default ResultItem;

interface iconContainerProps {
    bgcolor: string;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const IconContainer: any = styled(Box)<iconContainerProps>(
    ({ bgcolor }) => ({
        backgroundColor: bgcolor,
        height: "40px",
        width: "40px",
        padding: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "6px",
    }),
);
