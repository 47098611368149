import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import { ReactNode, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useEstimationsState } from "../../../store";
import { creationStatus } from "./creation-modal";
import { IconContainer } from "./result-item";
import ChargingStationIcon from "@mui/icons-material/ChargingStation";
import StatusTag from "../../../components/container/status-tag";
import ErrorModal from "./error-modal";

const statusToIcon: Record<creationStatus, ReactNode> = {
    loading: <CircularProgress size={20} />,
    error: (
        <StatusTag
            sx={{ width: "72px" }}
            bgcolor={enerbitColors.error[100]}
            fontcolor={enerbitColors.error.main}
        >
            Fallido
        </StatusTag>
    ),
    waiting: <MoreHorizIcon color="disabled" />,
    success: (
        <StatusTag
            sx={{ width: "72px" }}
            bgcolor={enerbitColors.success[100]}
            fontcolor={enerbitColors.success.main}
        >
            Creado
        </StatusTag>
    ),
};

interface Props {
    result: {
        status: creationStatus;
        key: string;
    };
}

const CreateEstimationItem = ({ result }: Props) => {
    const { meterModels } = useEstimationsState();
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    return (
        <>
            <SectionContainer sx={{ mb: 2 }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <IconContainer bgcolor={"#8B76D6"}>
                        <ChargingStationIcon sx={{ color: "#FFF" }} />
                    </IconContainer>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={2.4}>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    color: enerbitColors.neutral.main,
                                }}
                            >
                                Serial medidor
                            </Typography>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral[900],
                                    fontWeight: 700,
                                }}
                            >
                                {result.key.split("/")[0]}
                            </Typography>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    color: enerbitColors.neutral.main,
                                }}
                            >
                                Modelo medidor
                            </Typography>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral[900],
                                    fontWeight: 700,
                                }}
                            >
                                {meterModels.find(
                                    (model) =>
                                        model.code === result.key.split("/")[1],
                                )?.name ?? "Desconocido"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Typography
                                sx={{
                                    fontSize: "12px",
                                    color: enerbitColors.neutral.main,
                                }}
                            >
                                Fecha
                            </Typography>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral[900],
                                    fontWeight: 700,
                                }}
                            >
                                {result.key.split("/")[2]}
                            </Typography>
                        </Grid>
                        <Grid item xs={2.4}>
                            {statusToIcon[result.status]}
                        </Grid>
                        {result.status === "error" && (
                            <Grid item xs={2.4}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ height: "33px" }}
                                    onClick={() => {
                                        setShowErrorModal(true);
                                    }}
                                >
                                    Ver motivo
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </SectionContainer>
            <ErrorModal
                title="Creación fallida"
                message="La creación de la estimación falló. Esto puede ser causado por diferentes motivos como: el medidor no existe, el modelo del medidor es incorrecto, las fechas están en un formato incorrecto o fallas con nuestros servicios."
                isOpen={showErrorModal}
                handleClose={() => {
                    setShowErrorModal(false);
                }}
            />
        </>
    );
};

export default CreateEstimationItem;
