import StyledModal from "../../../../components/container/styled-modal";
import LoadEstimationsForm from "./load-estimations-form";
import { useCreateEstimationListContext } from "../../../../context/create-estimation-list";
import { useState } from "react";

interface Props {
    onClose: () => void;
    open: boolean;
}

export enum CreationSteps {
    FORM = 1,
    REQUESTS,
}

const LoadEstimationModal = ({ onClose, open }: Props) => {
    const { form, setEmptyFile } = useCreateEstimationListContext();
    const { reset } = form;
    const handleClose = () => {
        setEmptyFile(false);
        setCurrentStep(CreationSteps.FORM);
        reset();
        onClose();
    };
    const [currentStep, setCurrentStep] = useState<CreationSteps>(
        CreationSteps.FORM,
    );

    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            title="Cargar estimaciones"
        >
            {currentStep === CreationSteps.FORM && <LoadEstimationsForm />}
            {/* {currentStep === CreationSteps.REQUESTS && (
                <Box>Logica para enviar datos al back</Box>
            )} */}
        </StyledModal>
    );
};

export default LoadEstimationModal;
