import { api } from "@enerbit/base";
import { IReasonTypesResponse } from "../models";

export const getReasonTypes = async () => {
    try {
        const res = await api.get<IReasonTypesResponse>(
            "/estimations/reason-types?size=100&page=0",
        );

        return res.data.items;
    } catch (_error) {
        throw new Error("ErrGettingReasonTpes");
    }
};
