import { useEffect, useState } from "react";
import { InsertEstimationsListBody } from "../../../models";
import { useEstimationsState } from "../../../store";
import moment from "moment";
import { DATE_INPUT_FORMAT } from "../../../common";
import { insertEstimationList } from "../../../services";
import { Box, Divider, enerbitColors, Grid, Typography } from "@enerbit/base";
import CreateEstimationItem from "./create-estimation-item";
import { PreEstimationSteps } from "./pre-estimation";
import SectionContainer from "../../../components/container/section-container";
import Backbutton from "../../../components/Buttons/back-button";
import { useNavigate } from "react-router-dom";

interface Props {
    step: PreEstimationSteps;
}

export type creationStatus = "error" | "success" | "waiting" | "loading";

const CreationModal = ({ step }: Props) => {
    const [results, setResults] = useState<Record<
        string,
        { status: creationStatus; key: string }
    > | null>(null);
    const { estimationsList, estimationsListData, reasonTypes } =
        useEstimationsState();

    const navigate = useNavigate();

    const formatResultsKey = (data: InsertEstimationsListBody) => {
        return `${data.estimation_create.meter_serial}/${data.estimation_create.model_code}/${moment(data.estimation_create.start_at).utcOffset(0).format(DATE_INPUT_FORMAT)}`;
    };

    const fecthSequentially = async () => {
        if (!estimationsList) return;
        const values = Object.values(estimationsList);
        const requestsData: InsertEstimationsListBody[] = [];

        values.forEach((item) => {
            const keys = Object.keys(item);

            keys.forEach((key) => {
                const startAt = item[key][0].fecha;
                const endAt = item[key][item[key].length - 1].fecha;
                const body: InsertEstimationsListBody = {
                    estimation_create: {
                        end_at: endAt,
                        start_at: startAt,
                        meter_serial: item[key][0].serial,
                        model_code: `${item[key][0].modelo}`,
                        observations: estimationsListData.observation,
                        reason_type_id: estimationsListData.reasonId,
                    },
                    data: item[key].map((measure) => ({
                        active_energy_exported:
                            measure.energia_activa_exportada,
                        active_energy_imported:
                            measure.energia_activa_importada,
                        estimation_id: "",
                        reactive_energy_exported:
                            measure.energia_reactiva_exportada,
                        reactive_energy_imported:
                            measure.energia_reactiva_importada,
                        time_local_utc: measure.fecha,
                    })),
                };
                const resultKey = formatResultsKey(body);
                setResults((prev) => {
                    const nD = { ...prev };
                    nD[resultKey] = {
                        ...nD[resultKey],
                        status: "waiting",
                        key: resultKey,
                    };
                    return nD;
                });
                requestsData.push(body);
            });
        });

        for (const request of requestsData) {
            const key = formatResultsKey(request);
            setResults((prev) => {
                const nD = { ...prev };
                nD[key] = { ...nD[key], status: "loading", key };
                return nD;
            });
            try {
                await insertEstimationList(request);
                setResults((prev) => {
                    const nD = { ...prev };
                    nD[key] = { ...nD[key], status: "success", key };
                    return nD;
                });
            } catch (_error) {
                setResults((prev) => {
                    const nD = { ...prev };
                    nD[key] = { ...nD[key], status: "error", key };
                    return nD;
                });
            }
        }
    };

    useEffect(() => {
        if (step === PreEstimationSteps.CREATE) {
            fecthSequentially();
        }
    }, [step]);

    return (
        <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}>
                <Backbutton
                    onClick={() => {
                        navigate("/");
                    }}
                />
                <Box>
                    <Typography
                        sx={{
                            color: enerbitColors.primary.main,
                            fontSize: "28px",
                            fontWeight: 700,
                        }}
                    >
                        Creación de estimaciones
                    </Typography>
                </Box>
            </Box>
            <SectionContainer sx={{ mb: 3 }}>
                <Typography
                    sx={{
                        color: enerbitColors.primary.main,
                        fontSize: "20px",
                        fontWeight: 700,
                    }}
                >
                    Información
                </Typography>
                <Divider sx={{ my: 1, mx: "-12px" }} />
                <Grid container columnSpacing={2}>
                    <Grid item md={6}>
                        <Typography
                            sx={{
                                fontSize: "12px",
                                color: enerbitColors.neutral.main,
                            }}
                        >
                            Razón de la estimación
                        </Typography>
                        <Typography
                            sx={{
                                color: enerbitColors.neutral[900],
                                fontWeight: 700,
                            }}
                        >
                            {reasonTypes.find(
                                (reason) =>
                                    reason.id === estimationsListData.reasonId,
                            )?.description ?? "Desconocido"}
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <Typography
                            sx={{
                                fontSize: "12px",
                                color: enerbitColors.neutral.main,
                            }}
                        >
                            Observaciones
                        </Typography>
                        <Typography
                            sx={{
                                color: enerbitColors.neutral[900],
                                fontWeight: 700,
                            }}
                        >
                            {estimationsListData.observation}
                        </Typography>
                    </Grid>
                </Grid>
            </SectionContainer>
            <Typography
                sx={{
                    my: 2,
                    fontWeight: 700,
                    color: enerbitColors.primary.main,
                    fontSize: "20px",
                }}
            >
                Lista de medidores
            </Typography>
            {results &&
                Object.keys(results).map((key) => (
                    <CreateEstimationItem key={key} result={results[key]} />
                ))}
        </Box>
    );
};

export default CreationModal;
