import { styled } from "@enerbit/base";

const PaginationContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 15px;
`;

export default PaginationContainer;
