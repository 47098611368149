import { MeterModel } from "./meter";
import { IReasonTypes } from "./reason-types";

export interface IEstimationsState {
    loading: boolean;
    reasonTypes: IReasonTypes[];
    error: string;
    meterModels: MeterModel[];
    estimations: {
        items: SimpleEstimation[];
        currentPage: number;
        totalPages: number;
    };
    estimationDetail: SimpleEstimation | null;
    holes: EstimatedBatchGenericResponse[];
    estimationResults: IEstimationResults[];
    searchFilters: ISearchEstimationsFilter;
    estimationsList: Record<string, Record<string, ExcelMeasurements[]>> | null;
    estimationsListData: { observation: string; reasonId: string };
    getReasonTypes: () => Promise<void>;
    listEstimations: (
        props: Omit<SearchEstimationsParams, "filters">,
    ) => Promise<void>;
    getEstimationById: (id: string) => Promise<void>;
    uploadZeroEstimationFile: (data: ILoadZeroEstimation) => Promise<void>;
    createManualEstimation: (
        data: ILoadZeroEstimation,
    ) => Promise<IEstimationResults>;
    setFilters: (key: keyof ISearchEstimationsFilter, value: any) => void;
    setEstimationsList: (
        estimations: Record<string, Record<string, ExcelMeasurements[]>> | null,
    ) => void;
    resetFilters: () => void;
    insertEstimationsList: (
        estimations: Record<string, Record<string, ExcelMeasurements[]>>,
    ) => Promise<void>;
    setEstimationsListData: (data: {
        observation: string;
        reasonId: string;
    }) => void;
}

export type ILoadZeroEstimation = {
    reason_type_id: string;
    observations: string;
    file?: File;
    isMassive: boolean;
    meters: {
        meter_serial: string;
        meter_model: string;
        start_at: string;
        ended_at: string;
    };
};

export type ILoadEstimationList = {
    reason_type_id: string;
    observations: string;
    file?: File;
};

export interface ZeroEstimationExcel {
    serial: string;
    model: string;
    "fecha.incial": string;
    "fecha.final": string;
    __rowNum__: number;
}

export enum EZeroEstimationExcelError {
    BAD_SERIAL = 1,
    BAD_START_DATE,
    BAD_END_DATE,
    BAD_MODEL,
    EMPTY_FILE,
}

export interface IEstimationsResponse {
    items: Estimations[];
    page: number;
    size: number;
    total: number;
    pages: number;
    next_page: number;
    previous_page: null;
}

export interface Estimations {
    meter_serial: string;
    model_code: string;
    observations: string;
    start_at: string;
    end_at: string;
    reason_type_id: string;
    id: string;
    created_at: string;
    updated_at: string | null;
}

export interface SimpleEstimation {
    id: string;
    meterSerial: string;
    meterModel: string;
    estimationDate: string;
    estimationRange: string;
    startAt: string;
    endAt: string;
    reason: string;
}

export interface SearchEstimationsParams {
    filters: ISearchEstimationsFilter;
    page: number;
}

export interface EstimatedBatchGenericResponse {
    meter_serial: string;
    meter_model: string;
    start_date: string;
    end_date: string;
    start_hole: string | null;
    end_hole: string | null;
    observation: string;
}

export interface ICreateEstimation {
    end_at: string;
    meter_serial: string;
    model_code: string;
    observations: string;
    reason_type_id: string;
    start_at: string;
}

export interface ICreateEstimationResponse extends ICreateEstimation {
    created_at: string;
    updated_at: string | null;
    id: string;
}

export interface IEstimationResults {
    meter_serial: string;
    model_code: string;
    start_at: string;
    end_at: string;
    fail_reason?: string;
    id: string;
}

export interface ISearchEstimationsFilter {
    meter_serial: string;
}

export interface ExcelMeasurements {
    serial: string;
    modelo: number;
    fecha: string;
    energia_activa_importada: number;
    energia_activa_exportada: number;
    energia_reactiva_importada: number;
    energia_reactiva_exportada: number;
}

export interface InsertEstimationsListBody {
    data: InsertEstimationsListData[];
    estimation_create: {
        end_at: string;
        meter_serial: string;
        model_code: string;
        observations: string;
        reason_type_id: string;
        start_at: string;
    };
}

export interface InsertEstimationsListData {
    active_energy_exported: number;
    active_energy_imported: number;
    estimation_id: string;
    reactive_energy_exported: number;
    reactive_energy_imported: number;
    time_local_utc: string;
}

export interface InsertEstimationsListResponse
    extends InsertEstimationsListData {
    id: string;
    created_at: string;
    updated_at: string;
}

export enum EstimationQueryParamValues {
    ZERO = "zero",
    LIST = "list",
}
