import {
    Box,
    Button,
    CloseIcon,
    enerbitColors,
    Modal,
    Typography,
} from "@enerbit/base";
import Backbutton from "../../../../components/Buttons/back-button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface Props {
    handleClose: () => void;
    isOpen: boolean;
    handleAction: () => void;
}

const modalStyles = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "452px",
    bgcolor: "background.paper",
    p: 3,
    borderRadius: "10px",
};

const SuccessModal = ({ isOpen, handleAction, handleClose }: Props) => {
    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Box sx={modalStyles}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "right",
                    }}
                >
                    <Backbutton onClick={handleClose} icon={<CloseIcon />} />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 1,
                    }}
                >
                    <CheckCircleIcon
                        sx={{ fontSize: "72px" }}
                        color="success"
                    />
                    <Typography
                        sx={{
                            fontSize: "24px",
                            fontWeight: 700,
                            color: enerbitColors.success.main,
                        }}
                    >
                        Estimaciones creadas con éxito
                    </Typography>
                    <Typography
                        sx={{
                            color: enerbitColors.neutral.main,
                            textAlign: "center",
                        }}
                    >
                        La creación de estimaciones se completo correctamente,
                        recuerda validar si todos los medidores crearon
                        estimaciones.
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={handleAction}
                    >
                        Ver lista de medidores
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default SuccessModal;
