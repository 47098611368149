import { api } from "@enerbit/base";
import {
    EstimatedBatchGenericResponse,
    Estimations,
    ICreateEstimation,
    ICreateEstimationResponse,
    IEstimationResults,
    IEstimationsResponse,
    InsertEstimationsListBody,
    InsertEstimationsListResponse,
    ISearchEstimationsFilter,
    SearchEstimationsParams,
} from "../models";

export const listEstimationsService = async ({
    page,
    filters,
}: SearchEstimationsParams) => {
    try {
        const params: Record<string, string | number> = { page, size: 50 };

        Object.keys(filters).forEach((key) => {
            if (filters[key as keyof ISearchEstimationsFilter])
                params[key] = filters[key as keyof ISearchEstimationsFilter];
        });

        const res = await api.get<IEstimationsResponse>(
            "/estimations/estimations",
            { params },
        );

        return {
            items: res.data.items,
            totalPages: res.data.pages,
            currentPage: res.data.page,
        };
    } catch (_error) {
        throw new Error("ErrListEstimations");
    }
};

export const getEstimationById = async (id: string) => {
    try {
        const res = await api.get<Estimations>(
            `/estimations/estimations/${id}`,
        );

        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingEstimationById");
    }
};

export const uploadZeroEstimationFile = async (file: File) => {
    const formData = new FormData();

    formData.append("file", file);

    const res = await api.post<EstimatedBatchGenericResponse[]>(
        "/estimations/estimated-batch-generic",
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        },
    );

    return res.data;
};

export const createEstimation = async (data: ICreateEstimation) => {
    try {
        const res = await api.post<ICreateEstimationResponse>(
            "/estimations/estimations",
            data,
        );

        const result: IEstimationResults = {
            end_at: res.data.end_at,
            start_at: res.data.start_at,
            meter_serial: res.data.meter_serial,
            model_code: res.data.model_code,
            id: res.data.id,
        };
        return result;
    } catch (_error) {
        const result: IEstimationResults = {
            end_at: data.end_at,
            start_at: data.start_at,
            meter_serial: data.meter_serial,
            model_code: data.model_code,
            fail_reason: "ErrCreatingEstimation",
            id: "",
        };
        return result;
    }
};

export const insertEstimationList = async (body: InsertEstimationsListBody) => {
    try {
        const res = await api.post<InsertEstimationsListResponse[]>(
            "/estimations/estimated-measurements/insert-estimations-list",
            body,
        );
        return res.data;
    } catch (_error) {
        throw new Error("ErrInsertingEstimation");
    }
};
