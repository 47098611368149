import { styled, Select } from "@enerbit/base";

const CustomSelect = styled(Select)`
  border-radius: 12px;
  height: 41px;
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
  }
`;

export default CustomSelect;
