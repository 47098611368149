import saveAs from "file-saver";
import Papa from "papaparse";
import ShortUniqueId from "short-unique-id";

export const generateRandomId = () => {
    const { randomUUID } = new ShortUniqueId({ length: 10 });

    return randomUUID();
};

export const downloadCSVTemplate = (headers: string[][], fileName: string) => {
    const csvData = Papa.unparse(headers);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    saveAs(blob, `${fileName}.csv`);
};
