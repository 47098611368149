import {
    ExcelMeasurements,
    EZeroEstimationExcelError,
    ZeroEstimationExcel,
} from "../models";

export const validateZeroEstimationsFile = (data: ZeroEstimationExcel[]) => {
    const errors: { error: EZeroEstimationExcelError; row: number }[] = [];

    if (data.length === 0) {
        errors.push({ error: EZeroEstimationExcelError.EMPTY_FILE, row: 0 });
        return;
    }
    /**
     * Posibles validaciones:
     * 1. Formato del serial
     * 2. Que el modelo exista
     * 3. Validar formato de las fechas
     * 4. Validar que fecha inicio no sea mayor que fecha fin
     */
    data.forEach((data) => {
        const row = data.__rowNum__ + 1;
        const isValidSerial = /^\d{10}$/.test(data.serial);

        if (!isValidSerial) {
            errors.push({
                error: EZeroEstimationExcelError.BAD_SERIAL,
                row,
            });
        }
    });

    return errors;
};

export const convertStringToNumber = (value: string) => {
    const cleanedValue = value.replace(/,/g, ".").trim();

    const number = parseFloat(cleanedValue);

    return isNaN(number) ? null : number;
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function cleanFile(arr: any[]): ExcelMeasurements[] {
    return arr.filter((item): item is ExcelMeasurements => {
        return (
            typeof item.serial === "string" &&
            item.serial !== "" &&
            typeof item.modelo === "string" &&
            item.modelo !== "" &&
            typeof item.fecha === "string" &&
            item.fecha !== "" &&
            typeof item.energia_activa_importada === "string" &&
            item.energia_activa_importada !== "" &&
            typeof item.energia_activa_exportada === "string" &&
            item.energia_activa_exportada !== "" &&
            typeof item.energia_reactiva_importada === "string" &&
            item.energia_reactiva_importada !== "" &&
            typeof item.energia_reactiva_exportada === "string" &&
            item.energia_reactiva_exportada !== ""
        );
    });
}
