import React, { createContext, useContext, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ILoadZeroEstimation } from "../models";
import { loadZeroEstimationSchema } from "../validation";

interface CreateZeroEstimationContext {
    form: UseFormReturn<ILoadZeroEstimation>;
    emptyFile: boolean;
    setEmptyFile: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateZeroEsimationContext = createContext<
    CreateZeroEstimationContext | undefined
>(undefined);

export const useCreateZeroEstimationContext = () => {
    const context = useContext(CreateZeroEsimationContext);
    if (!context) {
        throw new Error("useFormContext must be used within a FormProvider");
    }
    return context;
};

export const CreateZeroEstimationFormProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const form = useForm<ILoadZeroEstimation>({
        resolver: zodResolver(loadZeroEstimationSchema),
        defaultValues: {
            reason_type_id: "",
            observations: "",
            file: undefined,
            isMassive: false,
            meters: {
                meter_serial: "",
                meter_model: "",
                start_at: "",
                ended_at: "",
            },
        },
    });

    const [emptyFile, setEmptyFile] = useState<boolean>(false);

    return (
        <CreateZeroEsimationContext.Provider
            value={{ form, emptyFile, setEmptyFile }}
        >
            {children}
        </CreateZeroEsimationContext.Provider>
    );
};
