import { z } from "zod";

export const meterSchema = z.object({
    meter_serial: z.string(),
    meter_model: z.string(),
    start_at: z.string(),
    ended_at: z.string(),
});

export const loadZeroEstimationSchema = z
    .object({
        reason_type_id: z.string().min(1, "Este campo es obligatorio."),
        observations: z.string().min(1, "Este campo es obligatorio."),
        isMassive: z.boolean(),
        file: z.instanceof(File).optional(),
        meters: meterSchema.optional(),
    })
    .refine(
        (data) => {
            if (data.isMassive) {
                return true;
            } else {
                return (
                    data.meters?.ended_at &&
                    data.meters?.meter_model &&
                    data.meters?.meter_serial &&
                    data.meters?.start_at
                );
            }
        },
        {
            path: ["meters"],
        },
    )
    .refine(
        (data) => {
            if (data.isMassive) {
                return data.file !== undefined;
            }

            return true;
        },
        {
            path: ["file"],
            message: "Este campo es obligatorio",
        },
    );

export const loadEstimationListSchema = z.object({
    reason_type_id: z.string().min(1, "Este campo es obligatorio."),
    observations: z.string().min(1, "Este campo es obligatorio."),

    file: z.instanceof(File, { message: "Este campo es obligatorio." }),
});
