import {
    Box,
    Typography,
    enerbitColors,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Button,
    Stack,
    Pagination,
    SxProps,
} from "@enerbit/base";
import PaginationContainer from "../../../components/container/pagination-container";
import { StyledTableHeader } from "../../../components/Table/StyledTable";
import AccumulatesModal from "./accumulates-modal";
import { useMemo, useState } from "react";
import { generateRandomId } from "../../../helpers";
import { ExcelMeasurements } from "../../../models";
import { useEstimationsState } from "../../../store";
import { useNavigate } from "react-router-dom";
import { PreEstimationSteps } from "./pre-estimation";

interface PresEstimationTableData {
    id: string;
    serial: string;
    model: string;
    startAt: string;
    endAt: string;
    monthKey: string;
    meterKey: string;
}

const detailButtonProps: SxProps = {
    minHeight: "unset !important",
    height: "33px",
    fontSize: "12px",
    with: "100px",
};

const ELEMENTS_PER_PAGE = 10;

interface Props {
    setCurrentStep: React.Dispatch<React.SetStateAction<PreEstimationSteps>>;
}

const PreEstimationTable = ({ setCurrentStep }: Props) => {
    const { estimationsList, meterModels } = useEstimationsState();
    const [showAccumulates, setShowAccumulates] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<
        ExcelMeasurements[] | null
    >(null);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/");
    };

    const startIndex = useMemo(() => {
        return (currentPage - 1) * ELEMENTS_PER_PAGE;
    }, [currentPage]);

    const endIndex = useMemo(() => {
        return startIndex + ELEMENTS_PER_PAGE;
    }, [currentPage]);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        setCurrentPage(value);
    };
    const getTableRows = () => {
        if (!estimationsList) return [];

        const rows: PresEstimationTableData[] = [];
        const excelData = Object.values(estimationsList);

        excelData.forEach((data) => {
            const keys = Object.keys(data);

            keys.forEach((key) => {
                const startAt = data[key][0].fecha;
                const endAt = data[key][data[key].length - 1].fecha;

                rows.push({
                    id: generateRandomId(),
                    monthKey: key,
                    meterKey: `${data[key][0].serial}-${data[key][0].modelo}`,
                    startAt,
                    endAt,
                    serial: data[key][0].serial,
                    model:
                        meterModels.find(
                            (model) => model.code === `${data[key][0].modelo}`,
                        )?.name ?? "Desconocido",
                });
            });
        });

        return rows;
    };

    const handleShowAccumulateData = (monthKey: string, meterKey: string) => {
        if (!estimationsList) return;
        setSelectedData(estimationsList[meterKey][monthKey]);
        setShowAccumulates(true);
    };
    return (
        <>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 3,
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                color: enerbitColors.primary.main,
                                fontSize: "28px",
                                fontWeight: 700,
                            }}
                        >
                            Confirmar datos del excel
                        </Typography>
                    </Box>
                </Box>

                <Table>
                    <TableHead>
                        <StyledTableHeader>
                            <TableCell>Serial medidor</TableCell>
                            <TableCell>Modelo medidor</TableCell>
                            <TableCell>Fecha inicio</TableCell>
                            <TableCell>Fecha fin</TableCell>
                            <TableCell>Acciones</TableCell>
                        </StyledTableHeader>
                    </TableHead>
                    <TableBody>
                        {getTableRows()
                            .slice(startIndex, endIndex)
                            .map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.serial}</TableCell>
                                    <TableCell>{row.model}</TableCell>
                                    <TableCell>{row.startAt}</TableCell>
                                    <TableCell>{row.endAt}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            sx={detailButtonProps}
                                            onClick={() => {
                                                handleShowAccumulateData(
                                                    row.monthKey,
                                                    row.meterKey,
                                                );
                                            }}
                                        >
                                            Ver acumulado
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <PaginationContainer sx={{ mt: 2 }}>
                    <Stack spacing={2}>
                        <Pagination
                            page={currentPage}
                            count={Math.round(
                                getTableRows().length / ELEMENTS_PER_PAGE,
                            )}
                            shape="rounded"
                            onChange={handlePagination}
                        />
                    </Stack>
                </PaginationContainer>

                <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                            setCurrentStep(PreEstimationSteps.CREATE)
                        }
                    >
                        Finalizar importanción
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleBack}
                        sx={{
                            backgroundColor: enerbitColors.neutral[200],
                            color: enerbitColors.neutral.main,
                            ":hover": {
                                backgroundColor: enerbitColors.neutral[200],
                            },
                        }}
                    >
                        Cancelar
                    </Button>
                </Box>
            </Box>
            {selectedData && (
                <AccumulatesModal
                    isOpen={showAccumulates}
                    handleClose={() => {
                        setSelectedData(null);
                        setShowAccumulates(false);
                    }}
                    data={selectedData}
                />
            )}
        </>
    );
};

export default PreEstimationTable;
