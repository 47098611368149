import { api } from "@enerbit/base";
import {
    EstimatedMeasurementsResponse,
    GetEstimatedMeasurementsParams,
} from "../models/estimated-measurements";

export const getEstimatedMeasurementsById = async ({
    estimationId,
    since,
    until,
}: GetEstimatedMeasurementsParams) => {
    try {
        const params = { since, until, estimation_id: estimationId };
        const res = await api.get<EstimatedMeasurementsResponse>(
            "/estimations/estimated-measurements",
            { params },
        );
        return res.data.items;
    } catch (_error) {
        throw new Error("ErrGettingEstimatedMeasurements");
    }
};
