import { initEnviroment, PrivateRoute, ThemeConfig } from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import MainLayout from "./layout/main-layout";
import Router from "./router/router";

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
    geopoliticsBaseUrl: process.env.REACT_APP_BASE_GEOPOLITICS_URL,
});

export default hot(function Root() {
    return (
        <ThemeConfig>
            <PrivateRoute>
                <MainLayout>
                    <Router />
                </MainLayout>
            </PrivateRoute>
        </ThemeConfig>
    );
});
