import {
    Box,
    CustomAlert,
    Divider,
    enerbitColors,
    moment,
    Skeleton,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useEstimatedMeasurementsState } from "../../../store/estimated-measurements";
import { useEffect, useMemo } from "react";
import { useEstimationsState } from "../../../store";
import { EstimatedMeasurement } from "../../../models/estimated-measurements";

const EstimatedData = () => {
    const {
        estimatedMeasurements,
        loading,
        getEstimatedMeasurementsById,
        // error,
    } = useEstimatedMeasurementsState((state) => state);
    const {
        estimationDetail,
        loading: loadingEstimations,
        error,
    } = useEstimationsState((state) => state);

    useEffect(() => {
        if (!estimationDetail) return;

        getEstimatedMeasurementsById({
            estimationId: estimationDetail.id,
            since: moment(estimationDetail.startAt)
                .utcOffset("-05:00")
                .format("YYYY-MM-DDTHH:mm:ssZ"),
            until: moment(estimationDetail.endAt)
                .utcOffset("-05:00")
                .format("YYYY-MM-DDTHH:mm:ssZ"),
        });
    }, [estimationDetail]);

    const isLoading = useMemo(() => {
        return loading || loadingEstimations;
    }, [loading, loadingEstimations]);

    return (
        <>
            {isLoading && (
                <Skeleton variant="rounded" height={500} sx={{ mt: 3 }} />
            )}
            {!isLoading && (
                <Box>
                    {estimatedMeasurements.length > 0 && (
                        <SectionContainer sx={{ mt: 2 }}>
                            <Typography
                                sx={{
                                    color: enerbitColors.primary.main,
                                    fontSize: "20px",
                                    fontWeight: 700,
                                }}
                            >
                                Datos estimados
                            </Typography>
                            <Divider sx={{ my: 1, mx: "-12px" }} />
                            <TableContainer
                                sx={{ maxWidth: "100%", height: "" }}
                                component={Box}
                            >
                                <Table stickyHeader sx={tableStyles}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                <AccessTimeIcon />
                                            </TableCell>
                                            {estimatedMeasurements.map((e) => (
                                                <TableCell
                                                    key={e.id}
                                                    align="center"
                                                >
                                                    {moment(e.time_local_utc)
                                                        .utcOffset("-05:00")
                                                        .format("HH:mm")}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableRows.map((r, index) => (
                                            <TableRow
                                                key={index + 1}
                                                sx={{
                                                    ".MuiTableCell-root": {
                                                        padding:
                                                            "8px 16px !important",
                                                    },
                                                }}
                                            >
                                                <TableCell
                                                    align="center"
                                                    sx={{
                                                        minWidth:
                                                            "200px !important",
                                                        maxWidth:
                                                            "215px !important",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: enerbitColors
                                                                .primary.main,
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {r.label}
                                                    </Typography>
                                                </TableCell>
                                                {estimatedMeasurements.map(
                                                    (e) => (
                                                        <TableCell
                                                            key={e.id}
                                                            align="center"
                                                            sx={{
                                                                minWidth:
                                                                    "150px",
                                                                maxWidth:
                                                                    "165px",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: enerbitColors
                                                                        .neutral[700],
                                                                    fontWeight: 700,
                                                                }}
                                                            >
                                                                {`${e[r.key]} ${r.units}`}
                                                            </Typography>
                                                        </TableCell>
                                                    ),
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </SectionContainer>
                    )}
                    {estimatedMeasurements.length === 0 && (
                        <Box my={1}>
                            <CustomAlert
                                severity="info"
                                text={
                                    "No se encontraron datos para esta estimación."
                                }
                                onClose={() => {}}
                            />
                        </Box>
                    )}
                    {!isLoading && error && (
                        <Box my={1}>
                            <CustomAlert
                                severity="error"
                                text={"Error cargando los datos estimados."}
                                onClose={() => {}}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

export default EstimatedData;

const tableRows: {
    label: string;
    key: keyof EstimatedMeasurement;
    units: string;
}[] = [
    {
        label: "Energía import. activa (Acumulado)",
        key: "active_energy_imported",
        units: "kW",
    },
    {
        label: "Energía import. reactiva (Acumulado)",
        key: "reactive_energy_imported",
        units: "kVAr",
    },
    {
        label: "Energía export. activa (Acumulado)",
        key: "active_energy_exported",
        units: "kW",
    },
    {
        label: "Energía export. reactiva (Acumulado)",
        key: "reactive_energy_exported",
        units: "kVAr",
    },
];

const tableStyles: SxProps = {
    "& th": {
        borderBottom: "1px solid #D9D2E6 !important",
        border: "1px solid #D9D2E6",
        backgroundColor: "#FFF !important",
        padding: "8px !important",
    },
    "& td": {
        padding: "8px !important",
        border: "1px solid #D9D2E6",
    },
};
