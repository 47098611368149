import { create } from "zustand";
import {
    GetEstimatedMeasurementsParams,
    IEstimatedMeasurementsState,
} from "../models/estimated-measurements";
import { ObjectKeyValue } from "@enerbit/base/common/models/customPropsComponents";
import { api } from "@enerbit/base";
import { getEstimatedMeasurementsById } from "../services";

export const useEstimatedMeasurementsState =
    create<IEstimatedMeasurementsState>()((set) => ({
        loading: false,
        observationTypes: {},
        estimatedMeasurements: [],
        error: "",
        getObservationTypes: async () => {
            try {
                const res = await api.get<ObjectKeyValue>(
                    "/estimations/search-observation-type",
                );

                set({ observationTypes: res.data });
            } catch (_error) {
                set({ error: "ErrGettingObsTypes" });
            }
        },
        getEstimatedMeasurementsById: async (
            params: GetEstimatedMeasurementsParams,
        ) => {
            try {
                set({ loading: true, error: "" });
                const data = await getEstimatedMeasurementsById(params);
                set({ loading: false, estimatedMeasurements: data });
            } catch (_error) {
                set({ error: "ErrGettingEstimatedMeasurements" });
            }
        },
    }));
