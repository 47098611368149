import { Box, NavSidebar } from "@enerbit/base";
import { ReactNode } from "react";

interface Props {
    children: ReactNode;
}

const MainLayout = ({ children }: Props) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                height: "100vh",
                width: "100vw",
                overflow: "hidden",
            }}
        >
            <NavSidebar />
            <Box
                sx={{
                    position: "relative",
                    display: "flex",
                    width: "calc(99.6vw - 70px)",
                    top: "70px",
                    height: "calc(100vh - 70px)",
                    backgroundColor: "white",
                }}
            >
                <Box
                    sx={{
                        zIndex: "0",
                        width: "100%",
                        overflowY: "scroll",
                        py: "40px",
                        px: "50px",
                    }}
                >
                    {children}
                </Box>
            </Box>
        </div>
    );
};

export default MainLayout;
