import { useNavigate } from "react-router-dom";
import { useEstimationsState } from "../../../store";
import { useEffect, useState } from "react";
import PreEstimationTable from "./pre-estimation-table";
import CreationModal from "./creation-modal";

export enum PreEstimationSteps {
    VERIFY = 1,
    CREATE,
}

const PreEstimation = () => {
    const { estimationsList } = useEstimationsState();
    const [currentStep, setCurrentStep] = useState<PreEstimationSteps>(
        PreEstimationSteps.VERIFY,
    );
    // const [showRequestModal, setShowRequestModal] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/");
    };

    useEffect(() => {
        if (!estimationsList) {
            handleBack();
        }
    }, []);

    return (
        <>
            {currentStep === PreEstimationSteps.VERIFY && (
                <PreEstimationTable setCurrentStep={setCurrentStep} />
            )}
            {currentStep === PreEstimationSteps.CREATE && (
                <CreationModal step={currentStep} />
            )}
        </>
    );
};

export default PreEstimation;
