import { create } from "zustand";
import { IMetersState, IModelsResponse } from "../models";
import { api } from "@enerbit/base";

export const useMeterState = create<IMetersState>()((set) => ({
    models: [],
    loading: false,
    error: "",
    getMeterModels: async () => {
        try {
            const res = await api.get<IModelsResponse>(
                "/meters/models/?size=100",
            );
            set({ models: res.data.items });
        } catch (_error) {
            set({ error: "ErrGettingModels" });
        }
    },
}));
