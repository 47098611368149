import {
    Box,
    Button,
    CardPdf,
    DownloadOutlinedIcon,
    DragZone,
    FormHelperText,
    InputLabel,
} from "@enerbit/base";
import { useCreateZeroEstimationContext } from "../../../../context/create-zero-estimation";
import { DropEvent, FileRejection } from "react-dropzone";
import { downloadCSVTemplate } from "../../../../helpers";

const UploadFile = () => {
    const { form } = useCreateZeroEstimationContext();
    const {
        setValue,
        watch,
        getValues,
        formState: { errors },
    } = form;

    const onDrop = (
        acceptedFiles: File[],
        _fileRejections: FileRejection[],
        _event: DropEvent,
    ) => {
        setValue("file", acceptedFiles[0]);
    };

    const onClearFile = () => {
        setValue("file", undefined);
    };

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <InputLabel required>Subir excel</InputLabel>
                <Button
                    variant="text"
                    color="secondary"
                    onClick={() =>
                        downloadCSVTemplate(
                            [
                                [
                                    "serial",
                                    "modelo",
                                    "fecha.inicial",
                                    "fecha.final",
                                ],
                            ],
                            "plantilla",
                        )
                    }
                    startIcon={<DownloadOutlinedIcon />}
                >
                    Descargar plantilla de excel
                </Button>
            </Box>
            {!!errors?.file && errors.file.message && (
                <FormHelperText error>{errors.file.message}</FormHelperText>
            )}
            {watch("file") && (
                <CardPdf
                    fileInfo={getValues("file")}
                    onClearFile={onClearFile}
                    isSuccess={true}
                    progress={100}
                    typeFile={"xlsx"}
                    isClearFile={true}
                />
            )}
            {!watch("file") && <DragZone typeFile="xlsx,csv" onDrop={onDrop} />}
        </Box>
    );
};

export default UploadFile;
