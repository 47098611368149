import { Box, CheckCircleIcon, Typography, enerbitColors } from "@enerbit/base";
import StyledModal from "../../../components/container/styled-modal";

interface Props {
    isOpen: boolean;
    handleClose: () => void;
    message: string;
    title: string;
}

const ErrorModal = ({ isOpen, handleClose, message, title }: Props) => {
    return (
        <StyledModal
            open={isOpen}
            onClose={handleClose}
            title=""
            styles={{ width: "452px", maxWidth: "452px" }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 1,
                }}
            >
                <CheckCircleIcon sx={{ fontSize: "66px" }} color="error" />
                <Typography
                    sx={{
                        fontSize: "22px",
                        fontWeight: 700,
                        color: enerbitColors.error.main,
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        color: enerbitColors.neutral.main,
                        textAlign: "center",
                        fontSize: "18px",
                    }}
                >
                    {message}
                </Typography>
            </Box>
        </StyledModal>
    );
};

export default ErrorModal;
