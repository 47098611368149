import { api } from "@enerbit/base";
import { IModelsResponse } from "../models";

export const getMeterModels = async () => {
    try {
        const res = await api.get<IModelsResponse>("/meters/models/?size=150");
        return res.data.items;
    } catch (_error) {
        throw new Error("ErrGettingModels");
    }
};
