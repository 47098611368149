import { Box, Button, enerbitColors, Typography } from "@enerbit/base";
import { useState } from "react";
import ZeroEstimationModal from "../zero-estimations/zero-estimations-modal";
import { CreateZeroEstimationFormProvider } from "../../../../context/create-zero-estimation";
import LoadEstimationModal from "../loaded-estimates/load-estimate-modal";
import { CreateEstimationListProvider } from "../../../../context/create-estimation-list";

const Header = () => {
    const [isZeroEstimationsOpen, setIsZeroEstimationsOpen] =
        useState<boolean>(false);
    const [isEstimationsListOpen, setIsEstimationsListOpen] =
        useState<boolean>(false);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 700,
                        color: enerbitColors.primary.main,
                        fontSize: "28px",
                    }}
                >
                    Estimaciones
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => setIsZeroEstimationsOpen(true)}
                    >
                        Crear estimaciones en cero
                    </Button>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setIsEstimationsListOpen(true)}
                    >
                        Crear estimaciones en lista
                    </Button>
                </Box>
            </Box>
            <CreateZeroEstimationFormProvider>
                <ZeroEstimationModal
                    open={isZeroEstimationsOpen}
                    onClose={() => setIsZeroEstimationsOpen(false)}
                />
            </CreateZeroEstimationFormProvider>
            <CreateEstimationListProvider>
                <LoadEstimationModal
                    open={isEstimationsListOpen}
                    onClose={() => setIsEstimationsListOpen(false)}
                />
            </CreateEstimationListProvider>
        </>
    );
};

export default Header;
