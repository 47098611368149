import {
    CustomAlert,
    enerbitColors,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    LoadingButton,
    MenuItem,
    Switch,
    TextField,
    Typography,
} from "@enerbit/base";
import { Controller } from "react-hook-form";
import CustomSelect from "../../../../components/Inputs/CustomSelect";
import StyledModal from "../../../../components/container/styled-modal";
import { useCreateZeroEstimationContext } from "../../../../context/create-zero-estimation";
import {
    EstimationQueryParamValues,
    ILoadZeroEstimation,
} from "../../../../models";
import { useEstimationsState } from "../../../../store";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MeterItem from "./meter-item";
import UploadFile from "./upload-file";
import SuccessModal from "../success-modal/success-modal";
import { estimationTypeQueryKey } from "../../../../common";

interface Props {
    onClose: () => void;
    open: boolean;
}

const ZeroEstimationModal = ({ onClose, open }: Props) => {
    const { form } = useCreateZeroEstimationContext();
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = form;

    const { reasonTypes, uploadZeroEstimationFile, createManualEstimation } =
        useEstimationsState((state) => state);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleClose = () => {
        reset();
        setError(false);
        setLoading(false);
        setSuccess(false);
        onClose();
    };

    const onSubmit = async (data: ILoadZeroEstimation) => {
        setLoading(true);
        try {
            if (data.isMassive) {
                await uploadZeroEstimationFile(data);
                navigate(
                    `/results?${estimationTypeQueryKey}=${EstimationQueryParamValues.ZERO}`,
                );
            } else {
                const estimation = await createManualEstimation(data);
                navigate(`/${estimation.id}`);
            }
        } catch (_error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <StyledModal
                open={open}
                onClose={handleClose}
                title="Crear estimaciones en cero"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container rowSpacing={2} columnSpacing={1}>
                        <Grid item md={12}>
                            <InputLabel required>
                                Razón de la estimación
                            </InputLabel>
                            <Controller
                                control={control}
                                name="reason_type_id"
                                defaultValue=""
                                render={({ field }) => (
                                    <CustomSelect
                                        {...field}
                                        fullWidth
                                        error={!!errors.reason_type_id}
                                    >
                                        {reasonTypes.map((r) => (
                                            <MenuItem key={r.id} value={r.id}>
                                                {r.description}
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                )}
                            />
                            {!!errors?.reason_type_id && (
                                <FormHelperText error sx={{ mx: "14px" }}>
                                    {errors.reason_type_id.message}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item md={12}>
                            <InputLabel required>Observaciones</InputLabel>
                            <Controller
                                control={control}
                                name="observations"
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        multiline
                                        rows={2}
                                        error={!!errors.observations}
                                        helperText={
                                            !!errors.observations &&
                                            errors.observations.message
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Controller
                                name="isMassive"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        sx={{ ml: "0px !important" }}
                                        control={
                                            <Switch
                                                {...field}
                                                checked={field.value}
                                                color="secondary"
                                            />
                                        }
                                        labelPlacement="start"
                                        label={
                                            <Typography
                                                sx={{
                                                    fontSize: "16px",
                                                    color: enerbitColors.primary
                                                        .main,
                                                }}
                                            >
                                                ¿Carga masiva de medidores?
                                            </Typography>
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        {!watch("isMassive") && (
                            <Grid item md={12}>
                                <MeterItem />
                            </Grid>
                        )}
                        {watch("isMassive") && (
                            <Grid item md={12}>
                                <UploadFile />
                            </Grid>
                        )}
                        <Grid item md={12}>
                            {!loading && error && (
                                <CustomAlert
                                    sx={{ mb: 2 }}
                                    severity="error"
                                    text={
                                        "Error creando las estimaciones. Intentalo nuevamente."
                                    }
                                    onClose={() => setError(false)}
                                />
                            )}
                            <LoadingButton
                                fullWidth
                                loading={loading}
                                variant="contained"
                                color="secondary"
                                type="submit"
                            >
                                Cargar estimaciones
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </StyledModal>
            <SuccessModal
                isOpen={success}
                handleClose={() => {
                    setSuccess(false);
                }}
                handleAction={() => {
                    setSuccess(false);
                }}
            />
        </>
    );
};

export default ZeroEstimationModal;
