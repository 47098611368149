import {
    Box,
    Button,
    CustomAlert,
    moment,
    Pagination,
    Stack,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@enerbit/base";
import Header from "./components/header/header";
import { useEstimationsState } from "../../store";
import { useEffect } from "react";
import TableSkeleton from "../../components/Table/TableSkeleton";
import { useNavigate } from "react-router-dom";
import Filters from "./components/filters/filters";
import { StyledTableHeader } from "../../components/Table/StyledTable";
import PaginationContainer from "../../components/container/pagination-container";
import { DATE_TIME_INPUT_FORMAT } from "../../common";

const detailButtonProps: SxProps = {
    minHeight: "unset !important",
    height: "33px",
    fontSize: "12px",
    width: "100px",
};

const noWrapCellStyle: SxProps = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "150px",
};

const EstimatesList = () => {
    const { listEstimations, estimations, loading, error } =
        useEstimationsState((state) => state);

    const navigate = useNavigate();

    useEffect(() => {
        listEstimations({ page: 0 });
    }, []);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        listEstimations({ page: value - 1 });
    };

    return (
        <Box>
            <Header />
            <Box mt={3} pb={3}>
                <Box sx={{ mb: 3 }}>
                    <Filters />
                </Box>
                {loading && <TableSkeleton columns={7} rows={20} />}
                {!loading && !error && estimations.items.length > 0 && (
                    <>
                        <Table>
                            <TableHead>
                                <StyledTableHeader>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Serial medidor</TableCell>
                                    <TableCell>Modelo medidor</TableCell>
                                    <TableCell>Fecha estimación</TableCell>
                                    <TableCell>Rango estimación</TableCell>
                                    <TableCell>Razón de estimación</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </StyledTableHeader>
                            </TableHead>
                            <TableBody>
                                {estimations.items.map((est) => (
                                    <TableRow
                                        key={est.id}
                                        sx={{
                                            ".MuiTableCell-root": {
                                                padding: "8px 16px !important",
                                            },
                                        }}
                                    >
                                        <TableCell>{est.id}</TableCell>
                                        <TableCell>{est.meterSerial}</TableCell>
                                        <TableCell>{est.meterModel}</TableCell>
                                        <TableCell>
                                            {est.estimationDate}
                                        </TableCell>
                                        <TableCell>
                                            {`${moment(est.startAt)
                                                .utcOffset("-05:00")
                                                .format(
                                                    DATE_TIME_INPUT_FORMAT,
                                                )} - ${moment(est.endAt)
                                                .utcOffset("-05:00")
                                                .format(
                                                    DATE_TIME_INPUT_FORMAT,
                                                )}`}
                                        </TableCell>
                                        <TableCell sx={noWrapCellStyle}>
                                            {est.reason}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                                sx={detailButtonProps}
                                                onClick={() =>
                                                    navigate(`/${est.id}`)
                                                }
                                            >
                                                Ver detalle
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <PaginationContainer sx={{ mt: 2 }}>
                            <Stack spacing={2}>
                                <Pagination
                                    page={estimations.currentPage + 1}
                                    count={estimations.totalPages}
                                    shape="rounded"
                                    onChange={handlePagination}
                                />
                            </Stack>
                        </PaginationContainer>
                    </>
                )}
                {!loading && error && (
                    <CustomAlert
                        severity="error"
                        text={"Error cargando la lista de estimaciones."}
                        onClose={() => {}}
                    />
                )}
            </Box>
        </Box>
    );
};

export default EstimatesList;
